<template>
  <div class="benefits rounded p-3">
    <b-row class="mx-3 border-bottom">
      <b-col>
        <h4>{{ $t("accountSettings.label.gettingPaid") }}</h4>
      </b-col>
      <b-col class="m-auto text-right">
        <b-button
          class="border-0 bg-transparent text-primary"
          :to="{ name: 'GettingPaidEdit' }"
        >
          <b-icon icon="pencil-square"></b-icon>
          {{ $t("global.button.edit") }}
        </b-button>
      </b-col>
    </b-row>

    <div v-for="(p, key) in paidUserList" :key="key" class="p-4">
      <h4>{{ p.Name }}</h4>
      <b-row v-for="f in p.Feilds" :key="f.FeildId">
        <b-col cols="auto">
          {{ f.Name }}
        </b-col>
        <b-col>
          {{ f.Value }}
        </b-col>
      </b-row>
    </div>
    <!-- <div>
      {{paidUserList}}
    </div> -->
    <!-- <b-table
      stacked
      :borderless="borderless"
      :items="items"
      :fields="fields"
    ></b-table> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      borderless: true,
      paidList: [],
      paidUserList: [],
      items: [
        {
          type: "Wire Transfer",
          bank_name: "National Bank",
          name_of_the_account_holder: "Account Holder 2021",
          instition_number: "183922292",
          transit_number: "3232-9220-2890",
          account_number: "1983"
        }
      ]
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "type",
          label: this.$t("accountSettings.label.type")
        },
        {
          key: "bank_name",
          label: this.$t("accountSettings.label.bankName")
        },
        {
          key: "name_of_the_account_holder",
          label: this.$t("accountSettings.label.accountHolder")
        },
        {
          key: "instition_number",
          label: this.$t("accountSettings.label.institutionNum")
        },
        {
          key: "transit_number",
          label: this.$t("accountSettings.label.transitNumber")
        },
        {
          key: "account_number",
          label: this.$t("accountSettings.label.accountNumber")
        }
      ];
    }
  },
  methods: {
    async gettingPaidList() {
      try {
        const response = await axios.post("Basic/GettingPaidList", {
          CountryCode: "CA"
        });
        this.paidList = response.data;
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getPaidUserList() {
      try {
        const response = await axios.get("user/GetGettingPaidUserList");
        this.paidUserList = response.data;
      } catch (error) {
        console.log(error.response.data);
      }
    }
  },
  beforeMount() {
    this.getPaidUserList();
  }
};
</script>
